.tmvc-pinFlowOpt-Main-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    //-webkit-justify-content: space-between;
    //justify-content: space-between;
    justify-content: flex-end;
    background-color: #fff;
    padding-left: 60px;
    //padding-right: 40px;
    margin: auto;
    margin-top: 6.2rem;
   // max-width: 1200px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0px;
        flex-direction: column-reverse;
        gap: 0;
        margin: auto;
        margin-top: 4rem;
    }
}

.tmvc-pinFlow-Search-container {
    display: flex;
    //padding-left: 24px;
    width: 50%;
    flex-direction: column;
    //align-items: flex-start;
    align-items: flex-end;
    gap: 32px;
    flex-shrink: 0;
    align-self: stretch;
    max-width: 600px;
    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 0 ;
    }
}
.pinFLow-imgContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex: 1 0 0;
    align-self: stretch;
    justify-content: center;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
}
.pin-top-container{
    width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        background-color: #fff;
        
        margin: auto;
        margin-top: 6.2rem;
        max-width: 1200px;
    
}
.pin-top-img-Container{
width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // flex: 1 0 0;
    align-self: stretch;
    justify-content: center;
}

.pinFlow-laningImg {
    max-width:100%;
    height:auto;
}
.pin-default-img-wrapper{
    width:50%;
    @media screen and (max-width: 500px) {
            width: 100%;
    }
}
.image-pin-container {
    width: auto;
    height: 100%; 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    @media screen and (max-width: 500px) {
        height: 25vh;
        }
}

.pin-inner-container {
    display: flex;
   // max-width: 490px;
    padding: 80px 48px 80px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    // gap: 32px;
    align-self: stretch;

    @media screen and (max-width: 500px) {
        padding: 24px 24px 40px 24px;
    }
}