.PackageInfoCard{
    display: flex;
    //align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    flex: 1 0;
    //padding-right: 40px;
    padding-top: 69px;
    margin:auto;
    @media screen and (max-width: 500px) {
        padding: 0px;
        width: 100%;
    }
}
.PackageInfoCard-container{
    display: flex;
    padding: 0px 0 0px 80px;
    //justify-content: flex-end;
    align-items: center;
    width: 500px;
    padding: 0px 320px 0px 0px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    margin-left:12px;
    @media screen and (max-width: 500px) {
        padding: 0px ;
        width: 100%;
        margin:0px
    }
    //flex: 1 0 0;
}
.PackageInfo-card-content{
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 5px;
    background: #FFF;
    backdrop-filter: blur(7.5px);
   
}
.pkgInfo-card-Header{
    display: flex;
    padding: 12px 0px;
    justify-content: center;
    align-items: baseline;
    align-self: stretch;
    background: #333;
    border-radius: 5px;
}
.pkgInfo-card-specialOfferText{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
}
.pkgInfo-card-maximilianoText{
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
    padding-left: 3px;
}
.pkfInfoCard-TopSection{
    display: flex;
    padding: 0px 48px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    @media screen and (max-width: 500px) {
            padding: 0px 24px;
        }
}
.pkfInfoCard-TopSection-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.pkfInfoCard-TopSection-price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.pkgCardTitle-cnt-Text{
    width: 260px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media screen and (max-width: 500px) {
            width: 100%;
        }
}
.pkgCardTitle-name-Text {
    align-self: stretch;
    color: #333;
    font-family: 'Arsenal', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
}
.pkgCardPrice-from-Text{
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}
.pkgCard-actualVal-Text {
    color:  #333;
    font-family: 'Arsenal', sans-serif;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}
.pkgCard-symbol-Text {
    color: #333;
    font-family: 'Arsenal', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
}
.pkgCardPrice-Value-Container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
}
.pkgCardPrice-value {
    color: var(--black-80, #333);
    text-align: center; 
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
}
.pkgCard-list-container {
    display: flex;
    padding: 0px 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    @media screen and (max-width: 500px) {
            padding: 0px 24px;
        }
    //padding: 0px 48px;
}
.pkgCard-list-head{
    width: 294px;
    color:#333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
}
.pkgCard-list-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.pkgCard-list-row-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}
.pkgCard-listRow {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    
}
.pkgCard-list-cl-check {
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 500px) {
            width:24px !important;
            height: 24px !important;
        }
}
 
.pkgCard-list-cl-detail {
    display: flex;
    align-items: flex-start;
    gap:  12px;
    color: #000;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
   // text-decoration-line: underline;
    border: none;
   // text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    background: #FFF;
   
}

.pkgCard-list-cl-detail:hover {
    //text-decoration-line: none;
}
.pkgCard-listRow-bonus {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 5px;
    background: #E8E5DE;
}

.bonus-cl-bonus-text {
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: -0.176px;
}

.bonus-cl-Gift-text {
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.176px;
}
.pkgInfoCard-pay-container {
    display: flex;
    padding: 0px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    @media screen and (max-width: 500px) {
            padding: 0px 24px;
        }
}
.pkgCard-pay-btn{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #000;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
}
.pkgCard-pay-btn:hover {
    background: #333;
    //color: #fff;
}
.pkgCard-detail-Paticipate{
    display: flex;
    height: 48px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color:  #000;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    border: none;
    background: #FFF;
}
.pkgCard-detail-Paticipate:hover {
    text-decoration-line: none;
}
.legalStatement-note{
    display: flex;
    gap: 12px;
    //color: #000; 
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    align-items: center;
    text-align: center;
    //padding: 0px 12px;
}


