.search-container {
    .col-md-12 {
        .button-box {
            display: flex;
            justify-content: center;
            align-items: center;

            .button-state-submit {
                color: #fff!important;
                background-color: #008577!important;
                font-size: 14px!important;
                width: 100%;
                border: 0;
                border-radius: 0!important;
                text-transform: uppercase;
                min-height: 50px;
                font-family: "Montserrat",sans-serif!important;
                font-weight: 600;
                letter-spacing: 1.2px;
                margin-top: 30px; }


            .button-state-submit-sheraton {
                color: #3a3a3a!important;
                background-color: #f7d6b4!important;
                font-size: 14px!important;
                width: 100%;
                border: 0;
                border-radius: 0!important;
                text-transform: uppercase;
                min-height: 50px;
                font-family: "proxima-nova"!important;
                font-weight: 600;
                letter-spacing: 0;
                margin-top: 30px; }

            .button-state-submit-westin {
                color: #51555a!important;
                background-color: #c5ddd1!important;
                font-size: 14px!important;
                width: 100%;
                border: 0;
                border-radius: 0!important;
                text-transform: uppercase;
                min-height: 50px;
                font-family: "proxima-nova"!important;
                font-weight: 600;
                letter-spacing: 0;
                margin-top: 30px; } }

        .mv-select {
            border-color: #424140!important; } } }


