.modal-overlay-part {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-part {
  background-color: #fff;
  padding: 40px;
  border-radius: 4px;
  width: 90%;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  //flex-direction: row-reverse;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 24px;
  overflow-x: hidden;
  @media screen and (max-width: 500px) {
    padding: 24px;
    width: 90%;
    max-width: 90%;
    max-height: 93%;
    padding-right: 18px;
  }
}

.modal-part-head-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  //align-items: center;
}
.modal-part-text-head {
  display: flex;
  padding: 0px 0px 20px 0px;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1 0 0;
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }
}
.modal-part-close {
  display: flex;
  //padding-left: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: -2vh;
  @media screen and (max-width: 500px) {
    margin-top: -1vh;
  }
}

.modal-content-part {
  display: flex;
  padding: 0px 0px 40px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100% !important;
  p {
    word-wrap: break-word !important;
    width: 100% !important;
  }
}
.modal-copy-section {
  display: flex;
  //padding: 0px 0px 40px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.modal-copy-header {
  align-self: stretch;
  color: #3c3c4d;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.modal-copy-content {
  align-self: stretch;
  color: #3c3c4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
