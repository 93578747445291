.btnCls {
  margin: auto;
  position: absolute;
  top: 40%;
  left: 46%;
}
.background {
  border: 2px solid #0576aa;
  height: 200px;
  width: auto;
  margin-left: 200px;
  margin-right: 200px;
  position: relative;
  margin-top: 50px;
}
.test-details-wrapper-container {
  padding-top: 30px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 6.2rem;
  h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 35px;
    color: #080808c7;
  }
}

.dropdownCls {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.test-details-select-container {
  justify-content: center;
  padding: 0.5em;
}
.test-Details-button-container {
  background: whitesmoke;
  padding: auto;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.test-input-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.displayAPIDataBtnCls {
  border: 1px solid black;
  width: 150px;
  height: 30px;
  background-color: rgb(219, 243, 243);
}
.test-input-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resortDetailsCls {
  border: 2px solid #040c07;
  height: 400px;
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;
}
.offerDetailsBtn {
  border: 1px solid black;
  padding: 4px;
  width: 100px;
  background-color: rgb(219, 243, 243);
  margin-left: 150px;
  margin-right: 10px;
}
.textDetailsCls {
  margin-left: 50px;
}
.textDetails {
  margin-right: 50px;
  float: right;
}
