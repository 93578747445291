@import '../../_variables';

.calendar-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  border: 1px solid $calendar-gray-color;

  @media screen and (max-width: 500px) {
    border: none;
  }
}
.calendar-reserved-date-container {
  justify-content: center;
  flex: 1;
  width: 49%;
  padding-bottom: 1rem;
}

.calendar-reserved-date-heading-text {
  font-weight: bold;
  flex: 1;
  padding-bottom: 0.2rem;
  font-size: 14px;
}
.calendar-reserved-date-text {
  font-size: 14px;
}

.calendar-placeholder {
  background-color: $white;
  height: 15rem;
  width: 20rem;
  border-top: 3rem solid $color-palm;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.calendar-button {
  width: 49%;
  height: 2.5rem;
  margin: 1.5rem 0 1.5rem 0;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: $white;
  letter-spacing: 1.5px;
  border: none;

  cursor: pointer;
  text-transform: uppercase;

  @media (max-width: $ipad) {
    width: 100%;
    display: block;
  }
}
.change-date {
  background: $color-palm;
}
.next {
  background: $nav-gray;
}
