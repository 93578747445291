//@import '../../_variables';

@mixin AdaSelector(
  $white,
  $color-stone,
  $color-light-gray,
  $ada-tooltip-box-shadow,
  $ada-questionmark-color,
  $roomtype-background-color,
  $roomtype-tile-color
) {
  .checkBoxWrapper {
    @media screen and (max-width: 767px) {
      float: left;
    }

    @media screen and (max-width: 575px) {
      flex-direction: column;
      label {
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .questionWrapper{
        margin-top: 24px !important;
      }
    }

    label {
      display: block;
      padding-left: 5px;
      text-indent: -10px;
    }
    input {
      width: 20px;
      height: 20px;
      padding: 0;
      margin: 10px;
      vertical-align: middle;
      position: relative;
      top: -1px;
      *overflow: hidden;
    }
    .footerText {
      font-size: 14px;
      margin-top: 0px;
    }
    .premiumTextHighSeason {
      float: right;
      font-size: 14px !important;
      @media screen and (max-width: 500px) {
        padding-left: 20px;
      }
    }

    select {
      outline: 0;
      box-shadow: none;
      border: 1px solid #66666666 !important;
      background: $white;
      background-image: none;
      position: relative;
      display: flex;
      width: 13em;
      height: 50px;
      line-height: 2.5;
      background: $white;
      // opacity: 40%;
      //overflow: hidden;
      // border-radius: 0.25em;
      @media screen and(max-width: 427px) {
        width: 80vw;
      }
    }
    .select::before {
      padding-right: 20px;
    }
    .adaText {
      margin-top: 15px;
      @media screen and (min-width: 991px) {
        text-align: end;
      }
      @media screen and (max-width: 500px) {
        margin-top: 0;
      }
    }

    .adaSelectorWrapper {
      display: flex;
      align-items: flex-start;

      .optionText {
        margin-left: 20px;
      }
    }
    .questionWrapper {
      margin-top: 10px;
    }
    .question-helper:before {
      content: '?';
      display: inline-block;
      font-family: sans-serif;
      font-weight: bold;
      text-align: center;
      width: 28px;
      height: 28px;
      font-size: 25px;
      line-height: 28px;
      border-radius: 50%;
      margin-right: 4px;
      color: $white;
      background: $ada-questionmark-color;
      border: 1px solid $ada-questionmark-color;
      text-decoration: none;
      margin-left: 5px;
    }
  }
  .tooltip-info {
    .tooltip-inner {
      background: $roomtype-background-color !important;
      color: $roomtype-tile-color !important;
      font-size: 0.75rem !important;
      text-align: start !important;
      min-width: 350px !important;
      border-radius: 5px !important;
      box-shadow: $ada-tooltip-box-shadow 0px 0px 29px 20px;
      padding: 15px;
    }
  }

  .bs-tooltip-auto[x-placement^='top'] .arrow::before,
  .bs-tooltip-top .arrow::before {
    border-top-color: $roomtype-background-color !important;
  }

  #ada-tooltip-info {
    opacity: 1 !important;
  }
}

.custom-hr {
  margin-top: 60px;
}
