.reserve-page-form-container {
  gap: 24px;
  display: flex;
  flex-direction: column;
}
.reserve-page-row {
  display: flex;
  align-items: stretch;
}
.reserve-page-column {
  flex-grow: 1;
}
.reservation-pay-now-btn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  color: #56565b;
}

.reservatioPageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.formCardTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.reservationCardTitle {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  font-family: 'Roboto', sans-serif;
}
.reservationCardDescription {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.nameRowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;
  }
}
.fnameGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}
.fNameLabel {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.reservationInputClass {
  display: flex;
  width: 100%;
  min-height: 44px;
  //padding: var(--spacing-12px, 12px) var(--spacing-16px, 16px);
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #eb0000;
  background: #fff;
}
.checkRowContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  padding-top: 20px;
}
.reservationCheckBox {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fff;
}
.reservationCheckDescription {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
  p {
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    font-family: 'Roboto', sans-serif;
  }
}
.payNowBtnContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
  }
  button {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
.continuePayBtn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  color: #fff;
  border-radius: 24px;
  background: #a45d23;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.continuePayBtnDisable {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #e6e6e6;
  color: #56565b;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.reservationInlineError {
  align-self: stretch;
  color: #eb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  font-family: 'Roboto', sans-serif;
}
.reserve-back-btn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #082940;
  color: #082940;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
  background: #fff;
}
.reserve-back-btn:hover {
  background: #082940;
  color: #fff;
}
.reserve-back-btn:hover .back-icon-contact-hover {
  display: block; /* Visible on hover */
}

.reserve-back-btn:hover .back-icon-contact {
  display: none; /* Hidden on hover */
}
.back-icon-contact {
  color: #082940;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
}
.back-icon-contact-hover {
  color: #082940;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  //opacity: 0;
  display: none;
}
