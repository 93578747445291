@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@500&family=Montserrat:wght@500&display=swap');

$font-montserrat: 'Montserrat', sans-serif;
$font-baskerville: 'Libre Baskerville', serif;

$color-palm: #008577;
$color-sky: #c9e9e6;
$color-stone: #5f5f5f;
$color-clay: #e8e5de;

$ipad: 768px;
$error-color: #b40000;

$primary-color: #5A5A5A;
$secondary-color-wcag: #128275;
$secondary-color: $secondary-color-wcag;
$tertiary-color: #c3b48e;
$copy-color: #5A5A5A;
$gray-element: #d5d5d5;
$white: #fff;
$black: #000;
$blue: #0000ff;
$othermonth-gray: #cec8c8b4;
$color-dark-gray: #efefef;
$color-light-gray: lightgray;
$nav-gray: #dfdfdf;
$color-eclipse: #3d3d3d;
$color-disabled: #808080;
$color-gold: gold;
$background_color: #cccccc;
$tooltipheader-color: #aeaeae;
$card-text-color: #646464;
$popover-border-color: #f8f8f8;
$color-hr: rgba(0, 0, 0, 0.1);
$calendar-gray-color: #D3D3D3;

$spinner-borderTopColor: #008577;
$spinner-borderColor: #53d3c4;

$room-information-bgcolor: #6969682a;
$room-info-bg-disable-color: rgb(245, 240, 240);

$ada-tooltip-box-shadow: rgba(100, 100, 111, 0.2);
