.hideonPrint{
  display: block !important;
    @media print {
        display: none !important;
      }
}
.showOnPrint{
  display: none !important ;
  @media print {
    display: block !important;
  }
}