.pinFlowOpt-err-Main-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    //align-items: flex-start;
    align-self: stretch;
    margin-top: 6.2rem;
    //max-width: 1200px;
    //padding-left: 24px;
    flex-direction: column;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0px;
        gap: 0;
        margin: auto;
        margin-top: 4rem;
    }
}

.pinFlowErrImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.pinOpt-err-container {
    display: flex;
    padding: 80px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    @media screen and (max-width: 500px) {
        padding: 0
    }
}

.pinOpt-err-inner-container {
    display: flex;
    width: 650px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    @media screen and (max-width: 500px) {
        width: 100%;
        display: flex;
        padding: 24px 24px 40px 24px;
        gap: 24px;
        align-self: stretch;
    }
}

.pinOpt-err-head-wrapper {
    color: #333;
    text-align: center;
    font-family: 'Arsenal', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
}

.pinOpt-err-textGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.pinOpt-err-desc {
    p {
        color: #333;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.pinOpt-err-phone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.pinOpt-err-phone {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
}

.pinOpt-err-phone-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pinOpt-err-phone-icon {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px;
    width: 24px;
    height: 26px;
}