.book-modal-overlay-part {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.book-modal-part {
    background-color: #fff;
    padding: 40px;
    padding-top: 0px;
    padding-left: 0px;
    border-radius: 4px;
    // width: 50%;
    max-width: 688px;
    // max-height: 50%;
    overflow: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    //flex-direction: row-reverse;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 24px;
    overflow-x: hidden;

    @media screen and (max-width: 500px) {
        padding: 24px;
        padding-top: 0px;
        width: 100%;
        max-width: 100%;
        max-height: 93%;
        //padding:0px;
        //padding-right: 18px;
    }
}

.book-modal-head-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    //align-items: center;
}

.book-modal-text-head {
    display: flex;
    padding: 40px 0px 20px 40px;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    color: #333;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    font-family: 'Arsenal', sans-serif;

    @media screen and (max-width: 500px) {
        padding: 24px 0px;
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
    }
}

.book-modal-part-close {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 500px) {
        //margin-top: -1vh;
    }
}

.book-modal-content-part {
    display: flex;
    padding: 0px 0px 20px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: 100% !important;
    font-family: 'Roboto', sans-serif;

    @media screen and(max-width: 500px) {
        padding: 0px 0px 40px 0px;
    }

    p {
        word-wrap: break-word !important;
        width: 100% !important;
    }
}

.modal-copy-section {
    display: flex;
    //padding: 0px 0px 40px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.modal-copy-header {
    align-self: stretch;
    color: #3c3c4d;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.modal-copy-content {
    align-self: stretch;
    color: #3c3c4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.book-bar{
    width:1px;
    height:100%;
    background: gray;
}
.book-phone-icon{
  width:30px;
  height:30px
}