.packageOverviewContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.packageCommonRow {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.packageHeaderContent {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  //width: 30%;
  font-family: 'Roboto', sans-serif;
}
.packageDescContent {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  flex: 1 0 0;
  width: 70%;
  font-family: 'Roboto', sans-serif;
}
.new-packageHeaderContent {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  //width: 30%;
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 500px) {
    //width: 41%;
  }
}
.new-packagebalancerContent {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  //width: 30%;
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 500px) {
    // width: 47%;
  }
}
.packageDescContent-bold {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  flex: 1 0 0;
  width: 70%;
  font-family: 'Roboto', sans-serif;
}
