.stepe-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media screen and (max-width: 500px) {
    padding: 40px 24px 0 24px;
  }
}
.stepWrapper {
  color: #000 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.title-step-wrapper {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  font-family: 'Arsenal', sans-serif;
}
