
@mixin  Search($white,$black,$error-color) {
  .card {
    border: none !important;
    border-radius: 0px;
    img {
      border-radius: 0px;
    }
    .card-img-overlay {
      position: absolute;
      top: 20%;
      right: 15%;
      bottom: auto;
      left: 15%;
      padding: 1.25rem;
      border-radius: calc(0.25rem - 1px);
      background-color: $white;
      color: $black;
    }
  
    input {
      width: 100%;
    }
    .inputWrapper label {
      width: 95%;
    }
    .orDiv,
    .flex2 {
      display: flex;
      align-items: center;
      span {
        padding: 20px;
      }
    }
  }
  .btn-success {
    height: 30px;
    border-radius: 0px !important;
    padding: 0px 0.75rem !important;
  }
  .error {
    color: $error-color;
    font-size: 0.9rem;
  }
  .centerOr {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .btnTop {
    padding-top: 10px;
  }
  .card-img-overlay {
    position: relative;
    top: 20%;
    right: 15%;
    bottom: auto;
    left: 15%;
    padding: 1.25rem;
    border-radius: calc(0.25rem - 1px);
    background-color: $white;
    color: $black;
  }
  .inputWrapper label {
    display: flex !important;
    flex-direction: column;
  }
  
  .inputWrapper {
    @media screen and (max-width: 650px) {
      margin-top: 40px;
    }
  }
  .StickyContainer {
    overflow: hidden;
    transition: margin 0.35s;
}
.btn-success {
  height: 30px;
  border-radius: 0px !important;
  padding: 0px 0.75rem !important;
}
.error {
  color: $error-color;
  font-size: 0.9rem;
}
.centerOr {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.btnTop {
  padding-top: 10px;
}
.card-img-overlay {
  position: relative;
  top: 20%;
  right: 15%;
  bottom: auto;
  left: 15%;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
  background-color: $white;
  color: $black;
}
.inputWrapper label {
  display: flex !important;
  flex-direction: column;
}

.inputWrapper {
  @media screen and (max-width: 650px) {
    margin-top: 30px;
  }
}
.StickyContainer {
  overflow: hidden;
  transition: margin 0.35s;
}
.PageWrapper.PageWrapper-noNav {
  padding-top: 0;
}
.Login-wrapper {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
  min-height: 100%;
  width: 100%;

  @media (min-width: 768px) {
    max-height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (max-width: 767px) {
    max-height: unset;
    //background: none !important;
    height: 100%;
    padding: 25px;
  }
  .PageWrapper.PageWrapper-noNav {
    padding-top: 0;
  }
  .Login-wrapper {
    -ms-flex-align: start;
    align-items: flex-start;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    min-height: 100%;
    width: 100%;
  
    @media (min-width: 768px) {
      max-height: 700px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  
    @media (max-width: 767px) {
      max-height: unset;
      background: none !important;
      height: 100%;
    }
  }
  .Card,
  .Card--selectable,
  .Card--selectable.Card--circle i {
    margin-bottom: 0.9375rem;
    padding: 25px;
    position: relative;
    border: 0;
    background-color: $white;
    margin: 0.9375rem auto;
    width: 944px;
  
    @media (max-width: 945px) {
      max-width: 944px;
      width: unset;
    }
  
    @media (max-width: 767px) {
      border: none;
      outline: none;
      width: 100%;
    }
  
    hr {
      border-top: 2px solid $color-hr;
      margin-top: 11px;
      margin-bottom: 11px;
    }
  
    form {
      margin: 0;
    }
  }
  .eligibilityList {
    & li {
      margin-bottom: 8px;
    }
    & ol {
      counter-reset: list;
    }
    ol li {
      margin-bottom: 2px !important;
    }
    & ol > li {
      list-style: none;
      position: relative;
    }
    & ol > li:before {
      counter-increment: list;
      content: counter(list, lower-alpha) ') ';
      position: absolute;
      left: -1.4em;
    }
  }
  .searchError {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    & div {
      text-align: center;
    }
    .timerStyle {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
  
  .search-container {
    //justify-content: center;
  
    @media screen and (min-width: 760px) {
      margin-bottom: 2px;
    }
  }
  
  .pin-input {
    text-transform: uppercase;
  }
  
  // .card-text {
  //   color: $card-text-color !important;
  // }
  
  .Card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
    @media screen and (min-width: 760px) {
      min-width: 280px;
      max-width: 420px;
      max-height: 500px;
      min-height: 350px;
    }
  }
}


.Card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media screen and (min-width: 760px) {
    min-width: 280px;
    max-width: 420px;
    max-height: 600px;
    min-height: 350px;
  }

  @media screen and(max-width: 440px) {
    padding: 20px 0 !important;
    max-height: 600px;
    min-height: 350px;
  }
}
}
.reservatioPageContainer{
    display: flex;
    justify-content: center;
      align-items: flex-end;
      flex: 1 0 0;
      align-self: stretch;
}

.nameRowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;
  }
}
.pin-input-cls {
  border: 1px solid#808080;
}
.pin-input-grp {
  margin-bottom: 32px;
  flex: 1 0 0;
}
.pin-error {
  margin-bottom: -28px;
}
.ln-input-grp {
  margin-bottom: 56px;
  flex: 1 0 0;
}
.ln-error {
  margin-bottom: -52px;
}
.offer-input-bill-grp {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;

  input {
    display: flex;
    width: 100%;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.pinCardTitleContainer {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin-bottom: 32px;
}
.pinCardDescription {
    align-self: stretch;
    color:#333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
}
.pin-head-wrapper {
    align-self: stretch;
    font-family: 'Arsenal', sans-serif;
    color: #333;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; 
}
.pinSubmitBtnContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
  }

  button {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

.pinSubmitBtn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  border-radius: 24px;
  background: #000;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.pinSubmitBtn:hover{
  background: #333;
}

.pinSubmitBtnDisable {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #e6e6e6;
  color: #56565b;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}