.custom-cyberSource-form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 0 !important;
}
.cardName-RowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.microform-lbl-grp {
  color: #000 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  margin-left: 0 !important;
  font-family: 'Roboto', sans-serif;
}
.microform-inline-err {
  align-self: stretch;
  color: #eb0000 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important; /* 166.667% */
  font-family: 'Roboto', sans-serif;
}
.microform-RowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;
  }
}
.exp-row-container {
  height: auto;
  width: calc(100% / 2);
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.cyber-formWrapper {
  padding-right: 3rem;

   label {
    width: 100%;
    margin-bottom: 0px;
  }

  .form-control {
    height: 40px;
  }

  .invalid-feedback-onchange {
   // color: 808080 !important;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 90%;

    .guest-error {
      margin-top: -17px;
    }
  }

  .form-control {
    border-radius: 0px !important;
    border: 1px solid #808080;
    font-weight: 500;
  }
}

@media (max-width: 690px) {
  .cyber-formWrapper {
    padding-right: 0rem !important;
    padding-top: 10px;
  }
}


.input-cyber-cls{
  border: 1px solid#808080;
  font-weight: 500;
  width: 100%;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
}
