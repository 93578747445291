.tmvc-res-main-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 60px;
  padding-right: 40px;
  margin: auto;
  margin-top: 6.2rem;
  max-width: 1200px;

  @media screen and (max-width: 500px) {
    padding-left: 0;
    padding-right: 0px;
    flex-direction: column-reverse;
    gap: 0;
    margin: auto;
    margin-top: 4rem;
  }
}
.resevre-package-container {
  display: flex;
  padding: 40px 24px 80px 24px;
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  align-self: stretch;
  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0 24px 40px 24px;
  }
}
.img-package-overview {
  width: 100%;
  height: 50%;
}
.package-overview-container {
  padding-top: 40px;
  padding-bottom: 80px;
  width: 40%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
