.orderSummary-container {
  width: 40%;
  display: flex;
  padding: 40px 24px 40px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.innerOrderSummaryContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background: #f2f2f2;
  @media screen and (max-width: 500px) {
    padding: 16;
  }
}
.inner-orderSummary-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
.orderSummary-title-wrapper {
  align-self: stretch;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.summary-resortInfo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.summary-resortInfo_desc-container {
  display: flex;
  padding: var(--spacing-8px, 8px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.orderSummary-dsc-name-wrapper {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  font-family: 'Roboto', sans-serif;
}
.desc-resort-detail-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #ac6523;
  color: #082940;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
}
.orderSummary-content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.ord-sum-trip-info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.tripInfo-row-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.tripInfo-head {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.tripInfo-content {
  flex: 1 0 0;
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.tripInfoDate-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  flex: 1 0 0;
}
.tripInfoDate-phone-container {
  display: flex;
  align-items: baseline;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.tripInfoDate-call-wrapper {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.tripInfoDate-phone-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #a45d23;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.orderSummaryDiveder {
  height: 1px;
  align-self: stretch;
  opacity: 0.25;
  background: #082940;
  width: 100%;
}
.tripInfo-title {
  height: 24px;
  align-self: stretch;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  font-family: 'Roboto', sans-serif;
}
.ord-sum-payment-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.summary-totalPrice-text {
  flex: 1 0 0;
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.summary-depositPaid-head {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
.summary-depositPaid-text {
  flex: 1 0 0;
  color: #a45d23;
  text-align: right;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 87.5% */
  font-family: 'Roboto', sans-serif;
}
.summary-tax-info-span {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
  p {
    align-self: stretch;
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    font-family: 'Roboto', sans-serif;
  }
}
.summaryBodyLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.summaryBodyLinkText {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: #082940;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  border: none;
  font-family: 'Roboto', sans-serif;
}
.summaryBodyLinkText:hover {
  text-decoration-line: none;
}
.travelDate-text-wrapper {
  display: flex;
}
