.reservationPageContainer {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.contactInfoHeaderContainer {
  position: absolute;
  right: 40px;
  padding-bottom: 40px;
}
.contactinfopageheadertitle {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  padding-top: 25px;
  padding-bottom: 20px;
  border-radius: 4px 4px 0px 0px;

  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 34px;
    padding: 0px 30px 0px 0px;
  }
}
.ineligibleModalTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-top: 16px;
  padding-bottom: 36px;
  @media screen and (max-width: 500px) {
    padding-bottom: 24px !important;
  }
}
.reservationCardTitle {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.close {
  font-size: 4rem;
  font-weight: 400;
}
.reservationCardDescription {
  align-self: stretch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.contactNumberDescription {
  color: #ac6523;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.checkRowContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.reservationCheckBox {
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fff;
  max-height: 762px;
}
.reservationCheckDescription {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media screen and (max-width: 500px) {
    padding-bottom: 20px !important;
  }
}
.closeBtnContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  grid-gap: 16px;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 20px 0px 24px;
  border-radius: 0px 0px 4px 4px;

  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
  }
  button {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
.payNowBtnContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-radius: 0px 0px 4px 4px;
  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
  }
  button {
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
.closeBtn {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 200px;
  border-radius: 24px;
  border: 1px solid #082940;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #082940;
}
.reservationInlineError {
  align-self: stretch;
  color: #eb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.modal-close-btn {
  color: #082940;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
  padding-top: 15px;
}
.modal-ineligible-state {
  max-width: 90% !important;
  .modal-content {
    height: auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 500px) {
      max-width: 100% !important;
      height: auto;
    }
  }

  @media screen and (max-width: 500px) {
    max-width: 100% !important;
  }

  .modal-body {
    margin-top: 15px;
    display: flex;
  }
}

@media (min-width: 900px) {
  .modal-ineligible-state {
    max-width: 1050px !important;
  }
}
