.modal-custom-dop {
  max-width: 90% !important;
  .modal-content {
    height: 80vh;
  }

  @media screen and (max-width: 500px) {
    max-width: 100% !important;
  }

  .modal-footer {
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
  }

  .modal-footer button {
    max-width: 20% !important;
  }

  .modal-body {
    margin-top: 15px;
    padding-left: 30px !important;
    padding-right: 30px !important;

    .hidden {
      font-size: 0;
      width: 1px;
      height: 1px;
      display: inline-block;
      overflow: hidden;
      position: absolute !important;
      border: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      clip: rect(1px, 1px, 1px, 1px);
    }

    p {
      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 900px) {
  .modal-custom-dop {
    max-width: 800px !important;
  }
}

.button-dop {
  border: 0;
  padding: 5px;
  @media screen and(max-width: 500px) {
    min-width: 150px !important;
    font-size: 12px;
  }
  
}
.button-dop:focus{
  border: 1px solid black;
}
