.leadInfoHeadContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.leadInfo-head-wrapper{
    color: #333;
    font-family: 'Arsenal', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
}
.leadInfo-head-desc{
    align-self: stretch;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.leadInfo-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.leadInfo-RowContainer{
    display: flex;
    min-width: 250px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}
.leadInfo-form-lbl{
    align-self: stretch;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
}
.leadInfo-form-data{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #333;
    text-overflow: ellipsis; 
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}
.leadInfo-consent-container{
    display: flex;
    min-width: 250px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.leadInfo-consent-CheckBox{
    display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;    
}
.leadInfo-consent-Desc {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;

    p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
    }
}
.leadInfo-consent-Desc-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}
.marketing-consent-btn{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    color: #000;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    border: none;
    background-color: #fff;
    margin-top: -1rem;
}
.marketing-consent-btn:hover {
   // background: #333;
    text-decoration-line: none;
    //color: #fff;
}
.leadInfo-btn-container{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
        flex-direction: column; 
        width: 100%;
    }
}
.myInfo-btn-enable{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #000;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
        width: 100%;
        }
}
.myInfo-btn-enable:hover {
    background: #333;
}

.myInfo-btn-disable{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #E6E6E6;
    color: #56565B;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    border: none;
    @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
.notMyInfo-btn{
    display: flex;
    height: 48px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: var(--INK-Black, #000);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    border: none;
    background-color: #fff;
    @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
.notMyInfo-btn:hover {
    // background: #333;
    text-decoration-line: none;
    //color: #fff;
}
.leadInfo-input-edit{
    display: flex;
    min-width: 250px;
    min-height: 44px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid #808080;
    background: #FFF;
    overflow: hidden;
    color:  #B3B3B3;
    text-overflow: ellipsis; 
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}