.thankYouTopSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.thankYouHeaderSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.thankYou-header-Title {
  color: var(--text-light-headline, #000);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  font-family: 'Arsenal', sans-serif;
}
.thankyou-header-detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-8px, 8px);
  align-self: stretch;
}
.header-mail-section {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
  p {
    font-family: 'Roboto', sans-serif;
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.header-mail-wrapper {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.header-phone-section {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
  p {
    color: #000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
}
.header-phone-wrapper {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.thankYou-desc-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-24px, 24px);
  align-self: stretch;
}
.thankYou-desc-header {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Arsenal', sans-serif;
}
.desc-list-container {
  display: flex;
  padding: var(--spacing-8px, 8px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-32px, 32px);
  align-self: stretch;
}
.desc-listItem-sction {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.list-item-number-div {
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1000px;
  background: #000;
}
.item-number {
  color: #fff;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 34px;
  font-family: 'Roboto', sans-serif;
}
.list-item-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.list-item-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  align-self: stretch;
  font-family: 'Roboto', sans-serif;
}
.list-item-desc {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
  font-family: 'Roboto', sans-serif;
}
.list-item-month-text {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.list-item-phone-text {
  color: #ac6523;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.travel-inspiration-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.travel-inspiration-header {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Arsenal', sans-serif;
}
.article-cards-container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.article-img-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fff;
}
.article-img-hoder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: 100%;
}
.article-img-content-section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.article-top-img-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.article-top-img-title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  align-self: stretch;
  font-family: 'Roboto', sans-serif;
}
.article-img-desc {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  //white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
}
.article-img-readMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  color: #082940;
  text-align: center;
  // font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  font-family: 'Roboto', sans-serif;
}
.OrderSummaryNumWraper {
  color: #a45d23;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  font-family: 'Roboto', sans-serif;
}
