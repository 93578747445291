.mailingDetails-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding-top: 20px;
}
.mailingDetails-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.mailingDetails-title-span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.mailing-RowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;
  }
}



.mailing-input-grp{
display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  input {
    display: flex;
    width: 100%;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.mailing-input-errCls {
  border: 1px solid #eb0000;
}

.mailing-input-cls {
  border: 1px solid#808080;
  background: #fff;
}
.mailing-input-readOnly-cls{
border: 1px solid#808080;
background: #E6E6E6;
}
.mailing-lbl-grp {
  color: #000 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}