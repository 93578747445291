.payment-error-details-container {
  margin-top: 6rem;
  padding-top: 11vh;
  padding-bottom: 11vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  p {
    @media screen and (max-width: 500px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .a {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 4px;
      gap: 4px;
      color: #a45d23;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      font-family: 'Roboto', sans-serif;
    }
  }
}
.phone-link {
  color: #a45d23;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
