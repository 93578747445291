.tool-tip {
    position: relative;
    bottom: 2px;
    .tool-tip-content {
        line-height: 14px;
        display: block;
        font-weight: bold;
        text-align: center;
        background: none;
        border: none;
        .question-icon {
            text-align: center;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background-color: #5f5f5f;
            color: #fff;
            display: block;
            z-index: 1;
            cursor: pointer; } }
    .tool-tip-popup {
        z-index: 100;
        font-family: "Arial";
        font-size: 12px;
        line-height: 16px;
        background-color: #fff;
        min-width: 300px;
        bottom: calc(100% + 6px);
        margin-bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        padding: 1rem;
        border: 1px solid #5f5f5f;
        position: absolute;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.25s;
        border-radius: 6px;
        box-shadow: 0 0 18px -12px #000;
        color: #5f5f5f;
        z-index: 10000;
        &::before {
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            content: "";
            width: 10px;
            height: 10px;
            border-right: solid 1px #5f5f5f;
            border-bottom: solid 1px #5f5f5f;
            background-color: #fff; } }
    .tool-tip-popup.center {
        left: 50%;
        transform: translateX(-50%);
        &::before {
            left: 50%;
            transform: translateX(-50%) rotate(45deg); } }
    .tool-tip-popup.right {
        left: 0;
        transform: translateX(0);
        &::before {
            left: 20px;
            transform: translateX(-50%) rotate(45deg); } }
    .tool-tip-popup.left {
        left: 22px;
        transform: translateX(-100%);
        &::before {
            right: 8px;
            left: auto;
            transform: translateX(-50%) rotate(45deg); } }
    .tool-tip-popup.show {
        opacity: 1;
        pointer-events: auto; }
    p {
        color: #5f5f5f; }
    dl {
        margin: 0;
        padding: 0; }
    dt {
        font-weight: bold;
        display: inline;
        margin-right: 6px; }
    dd {
        display: inline;
        margin: 0; } }

.tool-tip-item {
    margin-bottom: 0.9rem;
    &:last-child {
        margin-bottom: 0; } }

.tool-tip-contract {
    .tool-tip {
        .tool-tip-popup {
            min-width: max-content; } } }
