.tmvc-offer-main-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column; 
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background-color: #fff;
    margin: auto;
    margin-top: 6.2rem;
   // max-width: 1200px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0px;
       // flex-direction: column-reverse;
        gap: 0;
        margin: auto;
        margin-top: 4rem;
    }
}
.heroImg-container{
    display: flex;
    width:100%; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    align-self: stretch;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 1200px; 
    padding-bottom: 69px; 
    background-position: center center;
}
.pkgInfo-bottom-section {
    display: flex;
    padding: 80px 24px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}
.pinFlowPkg-Img{
    width:100%;
    height:auto;
}