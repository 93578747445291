.modal-Payment-err {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content-PaymentErr {
  position: relative;
  margin: auto;
  //padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #fff;
  padding-bottom: 24px;
}

/* The Close Button */
.close-pay-err {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-pay-err:hover,
.close-pay-err:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.paymentErr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  @media screen and (max-width: 500px) {
    gap: 8px;
  }
}
.payment-err-head-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
}
.paymentErrModal-title-container {
  display: flex;
  padding: 40px 0px 20px 40px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  @media screen and (max-width: 500px) {
    padding: 24px 0px 24px 24px;
  }
}
.PaymentErr-title-text {
  color: #000;
  font-family: 'Arsenal', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 34px;
  }
}
.paymentErr-content-section {
  display: flex;
  max-height: 762px;
  padding: 0px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #fff;
  @media screen and (max-width: 500px) {
    padding: 0px 24px;
  }
}
.paymentErr-desc-container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  @media screen and (max-width: 500px) {
    gap: 24px;
  }
}
.paymentErr-desc-text {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
}
.modalCloseContainer {
  display: flex;
  width: 100%;
  padding-left: 24px;
  padding-right: 3.8rem;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.text-err-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.close-text-span {
  color: #082940;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.payment-err-close-icon-wrapper {
  display: flex;
  padding: var(--spacing-24px, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.paymentErr-modal-close-btn {
  color: #082940;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
}
.img-title-container {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  align-items: center;
  padding-left: 5rem;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.header-title-text {
  color: #082940;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.back-icon-gallery {
  color: #082940;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
}
.paymentModalErr-section {
  display: flex;
  padding: 20px 40px 10px 40px;
  align-items: center;
  align-content: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 0px 0px 4px 4px;
  background: #fff;
}
.paymentErr-close-wrapper {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #082940;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.payErr-close-text-span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #082940;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
