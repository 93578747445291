.newOffer-loader-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  // background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 100;
  background-color: rgba(124, 124, 124, 0.96) !important;
}
.newOffer-loader-container {
  position: relative;
  margin: auto;
  //padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  //background: #fff;
  padding-bottom: 24px;
}
.spinner-container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
}
