@mixin tracking-to-spacing($tracking) {
  letter-spacing: calc((#{$tracking}* 1em) / 1000);
}

.topHeaderContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-shadow: 0px 3px 5px 0px #0000004d;
  width: 100%;
  padding: 1.5rem 0 1.5rem 0;
  position: fixed;
  z-index: 10;
  background: var(--surface-primary, #fff);
  margin-bottom: 5px;
  top: 0;
  @media screen and (max-width: 500px) {
    padding: 0.75rem 0 0.75rem 0;
  }
}
.mvc-topHeaderContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  box-shadow: 0px 3px 5px 0px #0000004d;
  width: 100%;
  padding: 2.2rem 0 2.21rem 0;
  position: fixed;
  z-index: 10;
  background: var(--surface-primary, #fff);
  margin-bottom: 5px;
  top: 0;

  @media screen and (max-width: 500px) {
    padding: 0.75rem 0 0.75rem 0;
  }
}
.innerHeaderContainer {
  display: grid;
  padding: 0 4rem 0 5.3rem;
  grid-template-columns: auto auto;
  width: 100%;
  @media screen and (max-width: 500px) {
    padding: 0 1.5rem 0 1.5rem;
  }
}
.innerPinHeaderContainer{
  display: grid;
    padding: 0 4rem 0 5.3rem;
    grid-template-columns: auto auto;
    width: 100%;
  
    @media screen and (max-width: 500px) {
      padding: 0 1.5rem 0 1.5rem;
    }
}

.img-header-logo {
  // flex-grow: 1;
  width: auto;
  height: 3rem !important;
  text-align: start;
  @media screen and (max-width: 767px) {
    width: auto;
    height: 3rem !important;
  }
  // @media screen and (min-width: 600px) {
  //   width: 1rem;
  //   height: 4.5rem !important;
  // }
}
.img-wvd-logo{
width: auto;
  height: 3rem !important;
  text-align: start;

  @media screen and (max-width: 767px) {
    width: auto;
    height: 3rem !important;
  }
}
.img-pinHeader-logo{
  width: auto;
  height: 1.5rem !important;
  text-align: start;
  
    @media screen and (max-width: 767px) {
      width: auto;
      height: 1.5rem !important;
    }
}

.img-phone-logo {
  width: 32px;
  height: 38px;
}
.phoneHeaderWraper {
  flex-grow: 9;
  text-align: end;
  align-self: center;
  a:hover {
    text-decoration: none;
  }
}
.textHeaderWraper {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #000;
}
.textHeaderLinkWraper {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border: none;
  border-bottom: 1px solid #ac6523;
  background-color: #ffff;
  margin-left: 4px;
  font-family: 'Roboto', sans-serif;
  color: #082940;
}
.textHeaderLinkWraper:hover {
  color: #082940;
  border-bottom: 1px solid #082940;
}
Login-wrapper {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
  min-height: 100%;
  width: 100%;

  @media (min-width: 768px) {
    max-height: 700px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media (max-width: 767px) {
    max-height: unset;
    //background: none !important;
    height: 100%;
    padding: 25px;
  }
  .PageWrapper.PageWrapper-noNav {
    padding-top: 0;
  }
}
.header-section-wrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.top-textHeaderWraper {
  display: flex;
  justify-content: flex-end;
}
.img-head-wrapper {
  display: flex;
}
.desk-phone-img{
  
}
