.pkgInfo-bottom-section {
    display: flex;
    padding: 80px 24px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    background:#333;
}
.packageInfoBtm-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;

}
.packageBtm-header-container{
    display: flex;
    //max-width: 650px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
   

}
.packageBtm-header-title {
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-family: 'Arsenal', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    h2{
    align-self: stretch;
        color: #FFF;
        text-align: center;
        font-family: 'Arsenal', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
    }
}
//.packageBtm-header-ruler {}
.packageBtm-header-desc {
    color: #FFF;
    text-align: center; 
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    p{
        color: #FFF !important;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}
.packageBtm-group-container{
    display: flex;
    max-width: 1200px;
    padding: 0px 24px;
    justify-content: space-between;
    //align-items: flex-start;
    align-items:center;
    //align-self: stretch;
    @media screen and (max-width: 500px) {
            flex-direction: column;
            width:100%;
            gap: 40px;
        }
}
.packageBtm-group-list {
    display: flex;
    max-width: 600px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}
.pkgBtm-list-grpText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //gap: 4px;
    //flex: 1 0 0;
}
.pkgBtmgrpText-step {
    align-self: stretch;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    h3{
        align-self: stretch;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
    }
}
.pkgBtmgrpText-step-desc {
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    p{
    color: #FFF !important;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}
.pkgCard-list-cl-step{
    width: 60px;
    height: 60px;
    @media screen and (max-width: 500px) {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
    }
}
.pkg-details-btm-bar{
    width:100px;
    height:2px;
    background: #fff;
}
.pkgCard-step-baseImg{
    display: flex;
    width: 80px;
    height: 80px;
    padding:24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 1000px;
    background: #FFF;
}
