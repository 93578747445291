.res-select-grp {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  select {
    display: flex;
    width: 100%;
    min-height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    background: #fff;
  }
}
.res-select-bill-grp {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  select {
    display: flex;
    width: 100%;
    min-height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-12px, 12px);
    align-self: stretch;
    border-radius: 5px;
    background: #fff;
  }
}
.res-select-clc {
  border: 1px solid #b3b3b3;
  background: #fff;
}
.res-select-readOnly-clc{
  border: 1px solid#808080;
    background: #E6E6E6 !important;
}
.res-err-select-clc {
  border: 1px solid #eb0000;
}
