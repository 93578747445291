.res-input-grp {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  input {
    display: flex;
    width: 100%;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.res-input-bill-grp {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  input {
    display: flex;
    width: 100%;
    min-height: 44px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.res-lbl-grp {
  color: #000 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.res-input-errCls {
  border: 1px solid #eb0000;
}
.res-input-cls {
  border: 1px solid#808080;
}
.res-inline-err {
  align-self: stretch;
  color: #eb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
}
