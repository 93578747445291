.billingDetails-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding-top: 20px;
}
.billingDetails-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.billingDetails-title-span {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.billing-RowContainer {
  display: flex;
  width: 100%;
  column-gap: 24px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 24px;
  }
}
.check-overide-mailAddress-Desc {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;

}
.overide-mailAddress-CheckBox{
  display: flex;
    width: 20px;
    height: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #fff;
}
