.modal-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content-img-gal {
  position: relative;
  margin: auto;
  //padding: 20px;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #fff;
  padding-bottom: 24px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-left: 5rem;
  padding-right: 5rem;
  @media screen and (max-width: 500px) {
    gap: 8px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.img-headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-left: 5rem;
  @media screen and (max-width: 500px) {
    margin-left: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.modalCloseContainer {
  display: flex;
  width: 100%;
  padding-left: 4px;
  padding-right: 3.8rem;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.text-link-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.close-text-span {
  font-family: 'Roboto', sans-serif;
  color: #082940;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.close-btn-wrapper {
  display: flex;
  width: 80px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex-shrink: 0;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.modal-close-btn {
  color: #082940;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px; /* 100% */
  text-transform: uppercase;
}
.img-title-container {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  align-items: center;
  padding-left: 5rem;
  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.header-title-text {
  color: #082940;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  font-family: 'Arsenal', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 24px;
    line-height: 34px;
  }
}
.imgRowContainer {
  display: flex;
  width: 100%;
  column-gap: 4px;
  justify-content: center;
  padding-left: 5rem;
  padding-right: 5rem;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.modal-image {
  height: auto;
  width: 100%;
}
.modal-image-small {
  height: auto;
  width: calc(100% / 2);
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.back-icon-gallery {
  color: #082940;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
}
