.tmvc-leadInfo-Main-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
   // -webkit-justify-content: space-between;
   // justify-content: space-between;
   justify-content:flex-end;
    background-color: #fff;
    padding-left: 60px;
    //padding-right: 40px;
    margin: auto;
    margin-top: 6.2rem;
    //max-width: 1200px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        padding-right: 0px;
        flex-direction: column-reverse;
        gap: 0;
        margin: auto;
        margin-top: 4rem;
    }
}

.leadInfo-container {
    display: flex;
    width:50%;
    //padding-left: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    //flex: 1 0 0;
    align-self: stretch;
    max-width: 600px;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0px;
       // flex-direction: column-reverse;
        gap: 0;
        margin: auto;
    }
}
.lead-main-img-wraper{
    width: 50%;
    @media screen and (max-width: 500px) {
            width: 100%;
        }
}
.pinFlowImg-bg-Lead{
    width: auto;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 500px) {
        height: 180px;
    }
}
.pinFLow-leadInfo-img {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex: 1 0 0;
    align-self: stretch;
    justify-content: center;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.pinFlowImg {
    max-width:100%;
    height:auto;
}

.leadInfo-inner-container {
    display: flex;
    max-width: 600px;
    padding: 80px 48px 80px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background:#FFF;

    @media screen and (max-width: 500px) {
        padding: 24px 24px 40px 24px;
    }
}