.packgeOverviewContainer {
  width: 40%;
  display: flex;
  padding: 40px 24px 40px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}
.innerPcOverviewContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background: #f2f2f2;
  @media screen and (max-width: 500px) {
    gap: 16px;
    padding: 16px;
  }
}
.pkg-inner-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.packageOverViewTitle {
  align-self: stretch;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.packagePriceContainer {
  display: flex;
  height: 24px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
}
.pkg-price-head {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
}
.pkg-price-content {
  color: #a45d23;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
}
.thumbnail-package-overview {
  display: flex;
  //width: 124px;
  //height: 124px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}
.packageOverviewOfferCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background: #fff;
}
.pkgOverviewOfferCardTitle {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
  border-radius: 5px 5px 0 0;
  font-family: 'Roboto', sans-serif;
}
.pkg-resortInfo-container {
  width: 100%;
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 8px;
  }
}
.resortInfo_img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34%;
  height: 34%;
  position: relative;
}
.resortInfo_desc-container {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.pkg-dsc-name-wrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 20px */
  font-family: 'Roboto', sans-serif;
}
.pkg-dsc-area-wrapper {
  align-self: stretch;
  color: #56565b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  font-family: 'Roboto', sans-serif;
}

.packageOverviewDiveder {
  height: 1px;
  align-self: stretch;
  opacity: 0.25;
  background: #082940;
  width: 100%;
}
.dueTodaycContent {
  color: #a45d23;
  text-align: right;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 87.5% */
  flex: 1 0 0;
  font-family: 'Roboto', sans-serif;
}
.packageBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.packageBodyTextWrapper {
  align-self: stretch;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
  p {
    color: #000 !important;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
}
.packageBodyLinkContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.packageBodyLinkText {
  color: #082940;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  text-align: center;
  border: none;
  font-family: 'Roboto', sans-serif;
  background: none;
}
.packageBodyLinkText:hover {
  text-decoration-line: none;
}

.Pkg-icon-container {
  opacity: 0.9;
  display: flex;
  //padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  //right: 8px;
  //bottom: 8px;
  //margin-left: 10.5vh;
  //margin-top: 8vh;
  right: 0;
  bottom: 0;
  border-radius: 24px;

  @media screen and (max-width: 500px) {
    // margin-left: 8vh;
    // margin-top: 6vh;
  }
}
.pkg-overview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
}
.seeDetails-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.seeDetail-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.seeDetail-linkText-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.seeDetail-linkText-span {
  color: #082940;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
}
.seeDetail-link-line {
  width: 100%;
  height: 1px;
  background: #ac6523;
}
.img-arrow-pkg {
  color: #082940;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Roboto', sans-serif;
}
