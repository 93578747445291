//@import '../../../_variables';

footer {
  width: 100%;
  position: static;
  bottom: 0;
}
.mainFooterWrapper {
  text-align: center;
  margin-bottom: -1.015rem;
}
.logoFooterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #e8e5de;
  padding: 32px 24px;
}
.ftBrandLogoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 1rem;
  padding-left: 0.438rem;
}
.bottomFooterWrapper {
  width: 100%;
  background: #f2f2f2;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  text-align: center;
  @media screen and (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #3c3c4d;
    font-family: 'Roboto', sans-serif;
  }
}
.bottomTextContentFooter {
  padding: 32px 24px 2px 24px;
  gap: 24px;
  text-align: start;
  max-width: 1200px;
  margin: auto;
}
.houseFooterContainer {
  display: flex;
  align-items: stretch;
  padding: 0px 24px 32px 24px;
  gap: 16px;
  text-align: start;
  max-width: 1200px;
  margin: auto;
}
.img-house {
  left: 0%;
  right: 96.83%;
  top: 0%;
  bottom: 85.8%;
  width: 39.11px;
  height: 25.71px;
  gap: 0px;
  opacity: 0.35px;
}
.houseTextContainer {
  display: flex;
  flex-direction: column;
}
.upperTextContainer {
  text-align: start;
}

.LowerTextContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    text-align: start;
    align-self: start;
    padding: 24px 0px;
  }

  p {
    @media screen and (max-width: 500px) {
      flex-direction: column;
      text-align: start;
      align-self: start;
      display: flex;
      gap: 20px;
    }
  }
  span,
  a {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #3c3c4d;
    align-self: center;
    font-family: 'Roboto', sans-serif;
    @media screen and (max-width: 500px) {
      align-self: start;
    }
  }
}
