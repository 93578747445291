.mv-select {
  width: 100%;
  height: 30px;
  position: relative;
  border: 1px solid #009587;
  border-radius: 2px;
  font-family: Arial;
  select {
    font-family: inherit; }
  .mv-select-tooltip {
      position: absolute;
      right: -18px;
      top: 3px; }
  label.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  select {
    height: 100%;
    width: 100%;
    padding: 0 10px;
    border-radius: 2px;
    border: none;
    margin: 0;
    background-color: white;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #5f5f5f;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Arial;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: radio-container;
    appearance: none;
    .hidden-for-label {
      display: none; }
    option {
      color: #009587;
      font-weight: 600; } }
  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -1px;
    pointer-events: none;
    border-radius: 0 2px 2px 0;
    border-right-color: #000; }
  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 18px;
    margin-top: -5px;
    border-width: 5px 5px;
    border-style: solid;
    pointer-events: none;
    border-color: #000 transparent transparent transparent;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 4px; }
  > span {
    position: absolute;
    top: -12px;
    right: 0px; }

  .mv-input-err-msg {
    margin-top: 5px;
    color: #EB0000 !important; }

  &.mv-select-error {
    border: 1px solid red; } }
